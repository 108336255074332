import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout/Layout";
import SEO from "../components/seo/Seo";
import Page from "../components/page/Page";
import Confidentiality from "../components/confidentiality/Confidentiality";
import i18nContext from "../components/i18n/i18n.context";

function ConfidentialitePage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={["gatsby", "react"]}
          location={location}
          metaDescription="Retrouvez notre politique de confidentialité pour comprendre le type d'informations personnelles que Jawg recueille."
          title="Confidentialité"
        />
        <Page>
          <Confidentiality />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

ConfidentialitePage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default ConfidentialitePage;
